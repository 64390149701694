import React from 'react'
import Hero from '../../components/Hero'
import Layout from '../../components/layout'
import { Img } from '@tueri/react'
import Helmet from 'react-helmet'

const meta = {
  title: 'Responsive Images',
  image: '274877906960',
  path: '/features/responsive-images/',
  description: 'Perfectly sized images for every device',
  keywords: 'responsive images, responsive images in react, faster website with responsive images'
}

const ImageOptimization = ({ data }) => (
    <Layout blog>
          <Helmet
            html={[
              { lang: 'en', prefix: 'og: http://ogp.me/ns#' }
            ]}
            meta={[
              { property: 'og:title', content: meta.title },
              { property: 'twitter:title', content: meta.title },
              { property: 'og:type', content: 'website' },
              { property: 'og:image', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'twitter:image:src', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'og:url', content: 'https://tueri.io' + meta.path },
              { property: 'og:description', content: meta.description },
              { property: 'twitter:description', content: meta.description },
              { name: 'description', content: meta.description },
              { name: 'keywords', content: meta.keywords },
              { name: 'author', content: 'Dane Stevens' },
              { property: 'twitter:card', content: 'summary_large_image' }
            ]}
          >
            <title>Tueri - { meta.title }</title>
          </Helmet>

        <Hero
            large
            title='Responsive images'
            subTitle={`Perfectly sized images for every device`}
        />

        <div style={{ marginBottom: '6rem' }}>
       <Img src='274877906960' alt='Responsive Images - Watch resized in Tablet and Phone' />
       </div>

       <h2>What are responsive images?</h2>
       <p>
        Responsive images are custom sized to the user's device. Your website loads faster on mobile by downloading smaller-size images for smaller devices and Google prioritizes faster websites in it's search algorithms.  
       </p>
       <p>
         The traditional method for making responsive images involves creating multiple sizes of the same image, usually sized for desktop, tablet and mobile screens. Additional sizes are be created for HiDPI or Retina displays.
        </p>
        <h2>Effortless responsive images</h2>
        <p>
         Tueri takes a new approach to responsive images using just-in-time (JIT) image generation. We automatically determine your user's screen size and display capabilities and then deliver an image sized exactly for their device.
       </p>

    </Layout>
)

export default ImageOptimization